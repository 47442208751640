import { Controller, useFormContext } from 'react-hook-form';
import { type ITextFieldProps, TextField } from '../components/TextField';
import { type BuildFormProps } from '../types/BuildFormProps';

export const FormTextField: React.FC<BuildFormProps<ITextFieldProps>> = ({
  control: controlFromProps,
  name,
  rules,
  defaultValue,
  shouldUnregister,
  transform,
  ...props
}) => {
  const { control: controlFromContext } = useFormContext() || {};

  const control = controlFromProps || controlFromContext;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <TextField
          value={transform?.input(field.value) ?? field.value}
          onChange={(e) => {
            const value = transform?.output(e.target.value) ?? e.target.value;
            field.onChange(value);
          }}
          error={!!fieldState.error}
          helperText={fieldState?.error?.message}
          {...props}
        />
      )}
    />
  );
};
